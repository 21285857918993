import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid';
import { API_URL } from "apiconfig";
import { Alert } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';


const initialRows = [
  {
    id: 1,
    firstName: 'Sabir',
    lastName: 'sayyad',
    role: 'Market',
    email: "test@g.com",
    password: "1234"
  },
  {
    id: 2,
    firstName: 'Sabir',
    lastName: 'sayyad',
    role: 'Market',
    email: "test@g.com",
    password: "1234"
  },
  {
    id: 3,
    firstName: 'Sabir',
    lastName: 'sayyad',
    role: 'Market',
    email: "test@g.com",
    password: "1234"
  },
  {
    id: 4,
    firstName: 'Sabir',
    lastName: 'sayyad',
    role: 'Market',
    email: "test@g.com",
    password: "1234"
  },
  {
    id: 5,
    firstName: 'Sabir',
    lastName: 'sayyad',
    role: 'Market',
    email: "test@g.com",
    password: "1234"
  },
];

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = uuidv4() + 'TEST'
    setRows((oldRows) => [{ id, isNew: true }, ...oldRows]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'firstName' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add User
      </Button>
    </GridToolbarContainer>
  );
}




const UserManagement = () => {
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [error, seterror] = useState(false);
  const [succcess, setsucccess] = useState(false);
  const [errormsg, seterrormsg] = useState();

  useEffect(() => {
    getUsers()
  }, []);


  const getUsers = async () => {

    await axios.get(`${API_URL}/auth/users`)

      .then((response) => {

        const modifiedData = response.data.map((item) => ({
          ...item,
          id: item._id,
        }));
        setRows(modifiedData); // Set the fetched data in state
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    // setshowLinear(true)

    await axios.delete(`${API_URL}/auth/deleteUsers/${id}`)
      .then((response) => {
        seterror(false)
        setRows(rows.filter((row) => row.id !== id));
      })
      .catch((error) => {
        seterror(true)
        seterrormsg('Error deleting data:', error)
      });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    if (updatedRow.email && updatedRow.firstName && updatedRow.lastName && updatedRow.password && updatedRow.role) {
      seterror(false)
      // setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      // return updatedRow;
      const obj = {
        "firstName": updatedRow.firstName,
        "lastName": updatedRow.lastName,
        "email": updatedRow.email,
        "password": updatedRow.password,
        "role": updatedRow.role
      }
      fetch(`${API_URL}/auth/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
      })
        .then((response) => {
          if (response.status !== 201) {
            seterror(true)
            seterrormsg(response.statusText)
          }
          else {
            seterror(false)
            setsucccess(true)
          }

          // console.log(response)
        })
        .catch((error) => {
          seterror(true)
          seterrormsg(error)
          console.error('Fetch error:', error);
        });
      return updatedRow;
    }
    else {
      setsucccess(false)
      seterror(true)
      seterrormsg("All Fields are mandatory")
    }


  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: 'firstName', headerName: 'First Name', width: 180, editable: true },
    { field: 'lastName', headerName: 'Last Name', width: 180, editable: true },
    {
      field: 'role',
      headerName: 'Role',
      width: 150,
      editable: true,
      type: 'singleSelect',
      valueOptions: ['Sales', 'Client'],
    },
    { field: 'email', headerName: 'Email Address', width: 250, editable: true },
    { field: 'password', headerName: 'Password', width: 150, editable: true },


    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          ,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="error"
          />,
        ];
      },
    },
  ];

  return (
    <div>

      {error ? <Alert severity="error" sx={{ fontSize: 16, mt: 2, mb: 2, fontWeight: 500 }} >{errormsg}</Alert> : null}
      {succcess ? <Alert severity="success" sx={{ fontSize: 16, mt: 2,  mb: 2,fontWeight: 500 }} >User Created Successfully</Alert> : null}

      <Box
        sx={{
          height: 500,
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
        />
      </Box>


    </div>
  );
};

export default UserManagement;
