import { Box, Grid, Typography } from '@mui/material';
import Navbar from 'scenes/navbar';
import Footer from '.';


const Privacy = () => {

    return (
        <>
          <Navbar />
            <Box padding="1rem">
            <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
                <h1 style={{ textAlign: "center" }}>Privacy & Policy</h1>
                <Typography variant='h3'>Overview</Typography>
                <br /><br />
                <Typography variant='h4' sx={{ color: "#247fe9" }}>Herr Enterprises respect your privacy.</Typography>
                <p>Herr Enterprises is associated with offering support to its clients for promoting and marketing the B2B products by generating effective leads from the target markets.</p>
                <p>It is Herr Enterprise's policy to respect your privacy regarding any information we may collect through registration forms, resource libraries, advertising units, widgets, web sites and web pages, whether accessed via computer, mobile or tablet device, or other technology (collectively, the "Service"), collection and licensing of data through third parties we work with, and how such information may be used and/or shared with others, how we safeguard it, and your choices in controlling its use in connection with our marketing activities.</p>
                <p>This privacy policy applies when you use our services.</p>
                <p>Herr Enterprises is 100% Can-Spam Compliant</p>
                <p>Herr Enterprises has a zero-tolerance spam policy. Any partner or publisher found to be using Herr Enterprise's promotional offers for spam will be immediately cut-off from use of the product. If you know of or suspect any violators, please notify us immediately at info@herr-enterprises.com
                </p>
                <Typography variant='h4' sx={{ color: "#247fe9" }}>Data Controller and Data Processor.</Typography>
                <p>We process two main types of personal data.</p>
                <h2><b>
                    Customer Data :</b></h2>
                <p>Personal data that forms part of data that is provided by our customers</p>
                <p>Personal data that we collect / we engage third parties to collect on behalf of our customers using campaign information provided by the customer</p>
                <p>Personal data that we purchase from third parties based on customer mandated criterion
                </p>
                <h2><b>Other Data:</b></h2>
                <p>Personal data about our customers, service providers, visitors and other individuals that is collected and processed directly by us.
                </p>
                <p>Our Customers are the controller of Customer Data. Herr Enterprises is the processor of Customer Data and the controller of Other Data.</p>
                <Typography variant='h4' sx={{ color: "#247fe9" }}>Data Collection.</Typography>
                <h2><b>Customer Data</b></h2>
                <p>Herr Enterprises collects this data based on our customer's instructions (also known as campaign information) through different channels including business partners (publishers) using relevant technology in web marketing, E mail marketing and Telemarketing.</p>
                <p>The leads generated by the publishers are intelligently filtered to improve their quality and converted into actionable marketing targets before being passed on to the customers.
                </p>
                <p>This data includes business contact information to match criterion provided by the customer.</p>
                <h2><b>Other Data</b></h2>
                <p>We also collect data when you use our applications and websites</p>
                <h2><b>Log Data</b></h2>
                <p>Our servers automatically collect information when you access or use our applications and services. This data is recorded in log files. Examples of such data include IP Address</p>
                <h2><b>Subscription Data</b></h2>
                <p>You provide personal data to us as part of signing up for our newsletter on our websites. We may also collect personal information from you when you use interactive features of the Websites, promotions, requesting customer support, or otherwise communicating with us.</p>
                <h2><b>Contact Us Data</b></h2>
                <p>When you enquire about our products and services or request for demonstration of our product, we collect and store this data to communicate with you and respond to your enquiry. Our purpose in collecting information is to help us provide you with better service, such as notifications about special offers and promotions, or other relevant content delivered through targeted advertising.</p>
                <p>
                    The leadhawks.io or its affiliate's website may also collect a recipient's email address to help you to initiate and email the recipient you have selected. The recipient may contact us at info@herr-enterprises.com to request that we remove this information.</p>
                <Typography variant='h4' sx={{ color: "#247fe9" }}> Cookies.</Typography>
                <p>We collect data through cookies</p>
                <p>Herr Enterprises uses cookies to help Herr Enterprises identify and track visitors, their usage of Herr Enterprises website, and their website access preferences. Herr Enterprises visitors can control cookies through your browser settings.</p>
                <p>For more details about how we use these technologies, please see our Cookie Policy How We Use the Information We Collect</p>
                <p>Customer Data will be used by Herr Enterprises in accordance with Customer's instructions, including any applicable terms in the Customer Agreement and as required by applicable law. Herr Enterprises is a processor of Customer Data and Customer is the controller.</p>
                <p>Herr Enterprises may use the information we obtain, license and collect about and from you for a number of business purposes, including for example, to: better tailor website and promotional content to visitor interests; verify your profile information; deliver targeted advertising; inform our partners of your business-related interests; improve the Service for internal business purposes; help our advertising partners better understand the audience they are reaching; and for purposes we disclose at the time you provide your Personal Information.
                </p>
                <Typography variant='h4' sx={{ color: "#247fe9" }}> Lawful basis for processing.</Typography>
                <p>We have lawful basis to process your personal data. We also use your consent as basis for lawfully processing your personal data.</p>
                <p>We process your personal data only when we have a lawful basis. Presently, we use the Performance of Contract (i.e. to deliver the services to our customers) and consent as the lawful basis for processing. For certain processing, we may also use legitimate interests as provided under the Data Protection Regulations.
                </p>
                <p>In some cases, we may also have a legal obligation to collect personal information from you or may otherwise need the personal information to protect your vital interests or those of another person. Where you have consented to a particular processing, you have a right to withdraw the consent at any time.</p>
                <h2><b>Types of HR Data Collected</b></h2>
                <p>


                    Herr Enterprises collects certain Personal Data from its Employees and Job Applicants, including without limitation (collectively, the "HR Data").
                    name,<br />
                    home address,<br />
                    telephone number(s),<br />
                    date of birth,<br />
                    social security number or other government identification number,<br />
                    salary,<br />
                    bank account details,<br />
                    job title,<br />
                    employment contracts,<br />
                    reviews and appraisals, competency assessments <br />
                    business travel details <br />
                    disciplinary records <br />
                    IP address, system-generated logs <br />
                    facts reported through the whistleblowing hotline or regular information and reporting channels, <br />
                    evidence gathered in the course of the investigation, report of the investigation and outcome of the report


                </p>
                <Typography variant='h4' sx={{ color: "#247fe9" }}>Purpose of Collection and Use of HR Data.</Typography>
                <p>Herr Enterprises processes HR Data relating to its Employees and Job Applicants in the US, UK or EEA for recruitment and HR management purposes at a global level, including:</p>
                <p>Carrying out and supporting Herr Enterprise's human resources functions and activities;</p>
                <p>Carrying out Herr Enterprise's obligations under employment contracts and employment and benefits laws;</p>
                <p>Managing workplace safety and health;</p>
                <p>Administering employee participation in benefits, compensation, human resources and training plans and programs;</p>
                <p>Managing employee performance;</p>
                <p>Implementing compliance and discipline procedures, and investigating and reporting on Employee compliance and discipline;</p>
                <p>Complying with Herr Enterprise's legal obligations and internal policies;</p>
                <p>Managing internal complaints or claims and litigation;</p>
                <p>Implementing and operating a whistle-blower hotline;</p>
                <p>Managing and conducting Herr Enterprise's business activities, including allocating company assets and human resources, project management, records management and compilation of audit trails or other reporting tools, managing mergers, acquisitions, sales, re-organizations or disposals and integration with purchaser;</p>
                <p>Operating IT and communication systems and monitoring Employee email and Internet usage to the extent permitted by applicable law.
                </p>
                <h2><b>Children</b></h2>
                <p>Herr Enterprises does not knowingly collect Personal or Usage Information from children under the age of 13 through https://Herr Enterprises.se, nor from any of our affiliates and partners. If you are under 13, please do not give us any Personal Information, and do not provide Personal Information to any website or web service without consulting your parent or guardian. If you have reason to believe that a child under the age of 13 has provided Personal Information to Herr Enterprises, please contact us, and we will seek to delete that information from our database.
                </p>
                <h2><b>Choice</b></h2>
                <p>Herr Enterprises is engaged in providing B2B services for customers to identify targets for the marketing of business products and in the process collects business contact data of companies and executives working in companies which are prospective customers for the products to be marketed.</p>
                <p>Herr Enterprises collect the data based on campaign information provided by Herr Enterprise's customers.</p>
                <Typography variant='h4' sx={{ color: "#247fe9" }}>Advertising / Behavioural Targeting; How to Opt-Out</Typography>
                <p>When you opt out of the Service, Herr Enterprises will no longer use or share any of your Personal or anonymous Usage Information, unless you recently submitted Personal Information (within last 30 days) in order to access free content, in which case only the Content Provider associated with the content you recently acquired will have access to your information. Please contact Herr Enterprises at info@herr-enterprises.com to opt out of this option.</p>
                <h2><b>Cross-Border Data Transfers</b></h2>
                <p>Your data will be stored and processed in multiple countries including outside of the European Union (EU) Region. The Service and the servers and facilities that maintain the data we hold, are operated in the Sweden/India. Given that we are an international business, our use of your information necessarily involves the transmission of data on an international basis. If you are located in the European Union, Canada or elsewhere outside of the United States, please be aware that information we collect may be transferred to and processed in United States/India.
                </p>
                <h2><b>Accountability for onward transfer</b></h2>
                <p>Data Integrity & Purpose Limitation</p>
                <p>Any personal information received from publishers in which personal data of EU citizens may be contained is treated as "EU Sensitive Data" and adequate technical and administrative controls are implemented across all the Herr Enterprises entities.</p>
                <p>The personal information is used only for the purpose for which it has been collected and is shared within the organization on a need to know basis.</p>
                <p>The technical and administrative controls ensure preservation of the confidentiality, integrity and availability of information as per the contractual obligations that Herr Enterprises has committed itself to. Data Security</p>
                <p>Herr Enterprises maintain reasonable and appropriate security measures to protect data and information under its control from unauthorized access, disclosure, misuse, loss or alteration.</p>
                <p>Herr Enterprise's security measures include industry-standard technology and equipment to help protect your information, and Herr Enterprises maintains security measures to allow only the appropriate personnel and contractors access to your information as well as policies and procedures to support implementation of various security controls. Unfortunately, no system can ensure complete security, and Herr Enterprises disclaims any liability resulting from use of the Service or from third party hacking events or intrusions.</p>
                <Typography variant='h4' sx={{ color: "#247fe9" }}>Data Retention</Typography>
                <h2><b>Customer Data:</b></h2>
                <p>We will retain this information for as long as needed to provide services to any one of our Customers or as otherwise authorized, directed or permissioned by our Customers. In addition, Herr Enterprises will retain this personal information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
                <h2><b>Other Data</b></h2>
                <p>We will retain this information for the duration of our business relationship and afterwards for as long as is necessary and relevant for our legitimate business purposes, in accordance with the Herr Enterprises Data Retention Policy or as otherwise permitted applicable laws and regulation. Where we no longer need your personal information, we will dispose of it in a secure manner (without further notice to you). Retention period for personal data and rationale for retention period is defined in Herr Enterprise's "Data Retention Policy".</p>
                <Typography variant='h4' sx={{ color: "#247fe9" }}>Your GDPR Rights</Typography>
                <p>You can request to access, update or correct your personal information. You also have the right to object to direct marketing.</p>
                <p>You may have additional rights pursuant to your local law applicable to the processing. For example: If the processing of your personal information is subject to the EU General Data Protection Regulation ("GDPR"), and your personal information is processed based on legitimate interests, you have the right to object to the processing on grounds relating to your specific situation. Under GDPR you may also have the right to request to have your personal information deleted or restricted and ask for portability of your personal information.</p>
                <p>If your personal information is processed under the Principles of the EU-U.S. Privacy Shield, you have the right to access to personal information about you that we hold and be able to correct, amend, or delete that information where it is inaccurate, or has been processed in violation of the Principles, except where the burden or expense of providing access would be disproportionate to the risks to the individual's privacy in the case in question, or where the rights of persons other than the individual would be violated.</p>
                <h2><b>Your Rights to Control Data</b></h2>
                <p>Whenever you use our services, we aim to provide you easy means to access, modify, delete, object to or restrict use of your personal information.
                    We strive to give you ways to access, update/modify your data quickly or to delete it unless we have to keep that information for legal purposes. Some rights can be accessed from within the Herr Enterprises application. For visitors, these rights can be exercised by contacting us with your specific request.</p>
                <p>If you are based within the EEA, or within another jurisdiction having similar data protection laws, in certain circumstances you have the following rights:
                    Change or Correct Data: You can edit some of your personal data through your account. You can also ask us to change, update or fix your data in certain cases, particularly if it is inaccurate.</p>
                <p>Delete Data: You can ask us to erase or delete all or some of your personal data (e.g. if it is no longer necessary to provide Services to you).
                    Object to, or Limit or Restrict, Use of Data: You can ask us to stop using all or some of your personal data (e.g. if we have no legal right to keep using it) or to limit our use of it (e.g. if your personal data is inaccurate or unlawfully held).</p>
                <p>Right to Access and/or Take Your Data: You can ask us for a copy of your personal data and can ask for a copy of personal data you provided in machine readable form.</p>
                <p>If you wish to access, verify, correct, or update your personal Information collected through the Service, you may contact us at info@herr-enterprises.com
                </p>
                <Typography variant='h4' sx={{ color: "#247fe9" }}>Herr Enterprises Services</Typography>
                <p>Herr Enterprises also collects information under the direction of any one of its Customers, in which case it collects cookie identifiers from the individuals. If you are a customer of any one of our Customers and would no longer like to be contacted by our Customer that uses our Service, please contact the Customer that you interact with directly. We may transfer personal information to companies that help us provide our Service. Transfers to subsequent third parties are covered by the service agreements with our Customers.</p>
                <p>An individual who seeks access, or who seeks to correct, amend, or delete inaccurate data should direct his query to the pertinent Herr Enterprise's Customer (the data controller).</p>
                <Typography variant='h4' sx={{ color: "#247fe9" }}>Other Web Sites</Typography>
                <p>The Service may contain links to, or integrations with other sites that Herr Enterprises does not own or operate. This includes links from customers and partners that may use the Herr Enterprises logo in a co-branding agreement, or websites and web services that we work with in order to provide the Service. Herr Enterprises does not control, nor is Herr Enterprises responsible for these sites or services, or their content, products, services, privacy policies or practices. If you submit personal information on a web site using the Service, you are choosing to disclose information to both Herr Enterprises and the third party with whose brand the website is associated. This privacy policy only governs Herr Enterprise's use of your information. The third party's use of that personal Information is governed by the partner's privacy policy, and not by this privacy policy.</p>
                <Typography variant='h4' sx={{ color: "#247fe9" }}>Business Sale</Typography>
                <p>As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, or a sale of our significant assets, we reserve the right to include any information we have among the assets transferred to the acquiring company.</p>
                <Typography variant='h4' sx={{ color: "#247fe9" }}>Social Media Widgets
                </Typography>
                <p>Our website includes social media features, such as the "Facebook Like" button, and Widgets, such as the "Share This" button or interactive mini-programs that run on our website. These features may collect your internet protocol address, which page you are visiting on our website, and may set a cookie to enable the feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on our website. Your interactions with these features are governed by the privacy statement of the company providing them.</p>
                <Typography variant='h4' sx={{ color: "#247fe9" }}>Blogs
                </Typography>
                <p>Our website offers publicly accessible blogs. You should be aware that any information you provide in these areas may be read, collected, and used by others who access them. To request removal of your personal information from our blog or community forum, contact us at info@herr-enterprises.com. In some cases, we may not be able to remove your personal information, in which case we will let you know if we are unable to do so and why.</p>
                <Typography variant='h4' sx={{ color: "#247fe9" }}>Changes to our Privacy Policy
                </Typography>
                <p>Please note, we may modify information presented via the Service and/or this privacy policy from time to time without prior notice to you, and any changes will be effective immediately upon the posting of the revised privacy policy. If we make any material changes, we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on this website prior to the change becoming effective. You are encouraged to periodically revisit the Herr Enterprises Privacy Policy to see if it has been updated. We will always show the date of the latest modification date of the Privacy Policy at the top of the page so you can tell when it has last been revised.</p>
                <Typography variant='h4' sx={{ color: "#247fe9" }}>Contact Us
                </Typography>
                <p>We regularly review our compliance with this privacy policy. Questions, comments and requests regarding this privacy policy are welcome and should be addressed to: info@herr-enterprises.com or call us at +12132123919</p>











            </Grid>
            <Grid item xs={1}></Grid>
        </Grid>
            </Box>
            <Footer />
        </>
    )

}
export default Privacy;