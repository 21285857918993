import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  user: null,
  token: null,
  role: 'Sales',
  modelOpen: false,
  loginModelOpen: false,
  posts: [],

};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginModelOpen:(state, action)=>{
      state.loginModelOpen = action.payload;
    },
    setModelOpen: (state, action) => {
      state.modelOpen = action.payload;
    },
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      // state.role = action.payload.user.role == 'admin' ? 'admin': 'sales';
      state.role = action.payload.user.role;
     
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
    },
  
  
  },
});

export const { setMode, setLogin, setLogout,setModelOpen, setLoginModelOpen } =
  authSlice.actions;
export default authSlice.reducer;
