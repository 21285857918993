import { Box, Typography, useTheme, useMediaQuery, Paper } from "@mui/material";
import Form from "./Form";
import Navbar from "scenes/navbar";
import image from '../images/Login Back 4.png'
import Footer from "scenes/footer";


const LoginPage = () => {

  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const logoStyle = {
    display: 'block',
    width: '300px', // Adjust the size as needed
  };

  const imgSTyle = {

  }
  return (
    <div style={{backgroundImage: `url('${image}')`, backgroundSize: 'cover',  backgroundRepeat: 'no-repeat',width: '100%', height: '100%' }}>
    <Box>
      <Navbar hide={true} />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

          }}>
          <Paper elevation={3} sx={{
            width: '28%',
            borderRadius: "0.75rem", p: "3rem", m: "2rem auto",
            borderRadius: "1.5rem"
          }}>

            <Typography variant="h2" textAlign={'center'} sx={{ color: '#474747', fontWeight: 500 }}>
              Sign in
            </Typography>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <img src='/logo.jpeg' alt="Logo" style={logoStyle} />
            </Box>

            <Form />
          </Paper>

        </Box>
        {/* <Footer/> */}
    </Box>
    </div>
  );
};

export default LoginPage;
