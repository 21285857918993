import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import Navbar from "scenes/navbar";
// import videoSource  from "../pormotion"
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import { setModelOpen } from "state";
import { useDispatch } from "react-redux";
import Footer from "scenes/footer";
const About = () => {
    const dispatch = useDispatch();
    return (
        <>
            <Box>
                <Navbar />
               
                <Box padding="1rem 6%"
                // sx={{
                //     display: 'flex',
                //     justifyContent: 'center',
                //     alignItems: 'center',
                // }}
                >   
                    <Button sx={{ fontSize: 25, mt: 5,color:'#333333' }} >About US </Button>
                    <Paper elevation={0} sx={{ borderRadius: "0.75rem", padding: 4, fontSize: '0.999rem', lineHeight: '1.85' }}>
                        <Typography variant="p" > In the ever-evolving landscape of B2B technology marketing, precision and insights are the cornerstones of success. At Herr Enterprises, we are proud to introduce TargetLynk, a game-changing SaaS solution that elevates your marketing strategies to unprecedented heights. Its an intelligent demand activation platform that goes beyond the conventional to deliver unmatched results.</Typography>
                    </Paper>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5, gap: 5 }}>
                        <Paper elevation={0} sx={{ borderRadius: "0.75rem", padding: 4, fontSize: '0.999rem', lineHeight: '1.75'  }}>
                            <Typography sx={{ fontSize: 17, fontWeight: 500 }} color={'primary'} mb={1}> UNLOCK ACCURATE BUYER INSIGHTS</Typography>
                            <Divider sx={{ mb: 2 }} />
                            <Typography variant="p" > TargetLynk is your key to unlocking the world of precise buyer insights. It dives deep into customer buying behavior and intent, providing you with invaluable knowledge to power your marketing strategies. With first-party intent leads at your fingertips, you can tap into the exact needs and preferences of your prospects.</Typography>
                        </Paper>

                        <Paper elevation={0} sx={{ borderRadius: "0.75rem", padding: 4, fontSize: '0.999rem', lineHeight: '1.75'  }}>
                            <Typography sx={{ fontSize: 17, fontWeight: 500 }} color={'primary'} mb={1}> SMART ANALYTICS FOR INFORMED DECISIONS</Typography>
                            <Divider sx={{ mb: 2 }} />
                            <Typography variant="p" > TargetLynk is your key to unlocking the world of precise buyer insights. It dives deep into customer buying behavior and intent, providing you with invaluable knowledge to power your marketing strategies. With first-party intent leads at your fingertips, you can tap into the exact needs and preferences of your prospects.</Typography>
                        </Paper>

                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5, gap: 5 }}>
                        <Paper elevation={0} sx={{ borderRadius: "0.75rem", padding: 4 , fontSize: '0.999rem', lineHeight: '1.75' }}>
                            <Typography sx={{ fontSize: 17, fontWeight: 500 }} color={'primary'} mb={1}> MEASURABLE RESULTS, EVERY TIME</Typography>
                            <Divider sx={{ mb: 2 }} />
                            <Typography variant="p" > With TargetLynk, we put effectiveness and engagement at the forefront. Our cross-channel platform operates seamlessly across multiple channels, delivering highly effective and engaged results. You'll see the difference in your marketing campaigns through measurable outcomes and improved ROI.</Typography>
                        </Paper>

                        <Paper elevation={0} sx={{ borderRadius: "0.75rem", padding: 4, fontSize: '0.999rem', lineHeight: '1.75'  }}>
                            <Typography sx={{ fontSize: 17, fontWeight: 500 }} color={'primary'} mb={1}> COMPLIANT AND SECURE</Typography>
                            <Divider sx={{ mb: 2 }} />
                            <Typography variant="p" > Rest easy knowing that TargetLynk complies with all data protection policies, ensuring that the data is handled with the utmost security and privacy. The integrity of data is paramount to us.

                                Elevate your B2B technology marketing with TargetLynk, where accurate buyer insights, smart analytics, and measurable results are the driving forces behind your success. Join us in transforming your marketing into a dynamic, data-driven force to be reckoned with.

                                Learn more about how TargetLynk can revolutionize your marketing efforts. Get started today and unlock the potential of your B2B campaigns.</Typography>
                        </Paper>

                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Button variant="contained" sx={{mt:2, mb:5}} startIcon={<BookmarksIcon/>}
                     onClick={() => dispatch(setModelOpen(true))}>Book a demo</Button>
                     
                     
                     </Box>


                </Box>

                <Footer/>
            </Box>
        </>
    )

}
export default About;