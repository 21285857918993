import { Box, Button, Chip, Divider, Grid, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { API_URL } from "apiconfig";
import axios from "axios";
import WidgetWrapper from "components/WidgetWrapper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const SideBar = ({ userId }) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const token = useSelector((state) => state.token);
  const friends = useSelector((state) => state.user.friends);

  const [topKeywords, settopKeywords] = useState([]);
  const [totalUniqueIntentSignals, settotalUniqueIntentSignals] = useState('');
  const [uniqueAccounts, setuniqueAccounts] = useState('');
  const [uniqueLocation, setuniqueLocation] = useState('');


  useEffect(() => {
    getSideBarInfo()
  }, []);

  const getSideBarInfo = async () => {

    await axios.get(`${API_URL}/leads/sideBarInfo`)

      .then((response) => {
        settopKeywords(response.data.topKeywords);
        settotalUniqueIntentSignals(response.data.totalUniqueIntentSignals);
        setuniqueAccounts(response.data.uniqueAccounts);
        setuniqueLocation(response.data.uniqueLocation);

       // console.log(topKeywords, totalUniqueIntentSignals, uniqueAccounts, uniqueLocation)
      })
      .catch((error) => {
       // console.error('Error fetching data:', error);
      });
  }


  return (
    <>
      <WidgetWrapper>
        <Typography
          color={palette.neutral.dark}
          variant="h5"
          fontWeight="500"
          sx={{ mb: "1.0rem", textAlign: 'center' }}
        >
          TRENDING TOPICS
        </Typography>

        <Divider sx={{ margin: "1.0rem 0" }} />
        <Grid container spacing={1} sx={{ mb: 1.5 }}>
          {topKeywords[0] ?
            <Grid item md={6}>
              <Chip label={topKeywords[0]._id} variant="outlined" color="success" />
            </Grid> :
            <Grid item md={6}>
              <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton>
            </Grid>

          }

          {topKeywords[1] ?
            <Grid item md={6}>
              <Chip label={topKeywords[1]._id} variant="outlined" color="success" />
            </Grid> :
             <Grid item md={6}>
            <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton>
            </Grid>
          }


        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {topKeywords[2] ?
            <Chip label={topKeywords[2]._id} variant="outlined" color="success" />
            :
            <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton>
          }
        </Box>

      </WidgetWrapper>

      <Box m="2rem 0" />

      <WidgetWrapper>
        <Typography
          color={palette.neutral.dark}
          variant="h5"
          fontWeight="500"
          sx={{ mb: "1.0rem", textAlign: 'center' }}
        >
          TOTAL UNIQUE INTENT SIGNALS
        </Typography>

        <Divider sx={{ margin: "1.0rem 0" }} />
        {totalUniqueIntentSignals ? <Box sx={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
          <Button sx={{ fontSize: 45 }} >{totalUniqueIntentSignals}</Button>
        </Box> :
          <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton>
        }

      </WidgetWrapper>

      <Box m="2rem 0" />
      <WidgetWrapper>
        <Typography
          color={palette.neutral.dark}
          variant="h5"
          fontWeight="500"
          sx={{ mb: "1.0rem", textAlign: 'center' }}
        >
          TOTAL UNIQUE ACCOUNTS
        </Typography>

        <Divider sx={{ margin: "1.0rem 0" }} />
        {uniqueAccounts ?
          <Box sx={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            <Button sx={{ fontSize: 45 }} >{uniqueAccounts}</Button>
          </Box> :

          <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton>
        }

      </WidgetWrapper>

      <Box m="2rem 0" />
      <WidgetWrapper>
        <Typography
          color={palette.neutral.dark}
          variant="h5"
          fontWeight="500"
          sx={{ mb: "1.0rem", textAlign: 'center' }}
        >
          TOTAL UNIQUE INTENT LOCATIONS
        </Typography>

        <Divider sx={{ margin: "1.0rem 0" }} />
        {uniqueAccounts ?
          <Box sx={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            <Button sx={{ fontSize: 45 }} >{uniqueLocation}</Button>
          </Box> :

          <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton>
        }


      </WidgetWrapper>
    </>
  );
};

export default SideBar;
