import React, { useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Pie } from '@ant-design/plots';

const PieChar = ({ data, viewability }) => {
  
  const config = {
    autoFit: 'true',
    height: 300,
    data: data,
    angleField: 'value',
    colorField: 'type',
    color: ({ type }) => {
      if (type === 'Impressions') {
        return '#4f81bc'
      } else if (type === 'Engaged') {
        return '#26a69a'
      }
    },
   
    radius: 1,
    innerRadius: 0.75,
    startAngle: Math.PI,
    endAngle: Math.PI * 2,

    label: {
      type: 'inner',
      offset: '-50%',
      //   content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,

      style: {
        fontSize: 12,
        textAlign: 'center',

      },
    },
    interactions: [
      {
        type: 'element-active',
      },

    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          fontSize: 16,
          textAlign: 'center',
        },
        content: `<span style="margin-top: 35px;" >${viewability} <p> Overall Viewability</p></span>`
      }
    }
  };

  const memoizedPie = useMemo(() => {
    return(<Pie {...config} />) 
  }, [data]);

  return memoizedPie;






};

export default PieChar;
