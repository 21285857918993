import { Box, Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";


const Footer = () => {
    const navigate = useNavigate();
    return (
        <>

            <Box sx={{
                display: 'flex', justifyContent: 'center',
                alignItems: 'center', gap: 5, mb: 1
            }}>
                <Typography sx={{ cursor: 'pointer', fontWeight: 500 }} color={'primary'}
                    onClick={() => {
                        navigate("/privacyPolicy");
                    }}
                >Privacy & Policy</Typography>
                <Typography sx={{ cursor: 'pointer', fontWeight: 500 }} color={'primary'}
                    onClick={() => {
                        navigate("/dataProtectionPolicy");
                    }}
                >Data Protection Policy</Typography>

            </Box>

            {/* <Divider variant="middle" sx={{ color: '#afafaf' }} /> */}
            <Box padding="1rem 6%"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >


                <Typography variant="body1" sx={{ color: '#afafaf' }}>Copyright © 2023 Herr Marketing Services Pvt Ltd. All Rights Reserved.</Typography>
            </Box>
        </>
    )

}
export default Footer;