import { Box, Tab, Tabs, Typography } from "@mui/material";

import Navbar from "scenes/navbar";

import AddSalesUser from "./AddSalesUser";
import ManageLeads from "./ManageLeads";
import { useState } from "react";
import Footer from "scenes/footer";
import UserManagement from "./UserManagement";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AdminPage = () => {
  const [value, setValue] = useState(0);
  const [data, setdata] = useState([
    { id: 1, Keyword: 'Email Marketing', Region: 'United States', First_Name: 'Sabir', Last_Name: 'Sayyad', Email_Address: 'sabir@sabzcorp.in', Company_Name: 'Barclays', Action: 'Downloaded White paper', Intent_Score: 9.5 },
    { id: 2, Keyword: 'Email Marketing', Region: 'United States', First_Name: 'Adnan', Last_Name: 'Attar', Email_Address: 'adnan@capgemini.com', Company_Name: 'Capgemini', Action: 'Downloaded White paper', Intent_Score: 2.5 },
    { id: 3, Keyword: 'Email Marketing', Region: 'United States', First_Name: 'Sohel', Last_Name: 'Shaikh', Email_Address: 'sohel@atos.com', Company_Name: 'Atos', Action: 'Downloaded White paper', Intent_Score: 4.5 },
    { id: 4, Keyword: 'Email Marketing', Region: 'United States', First_Name: 'Rameez', Last_Name: 'Tamboli', Email_Address: 'rameez@syntel.com', Company_Name: 'Syntel', Action: 'Downloaded White paper', Intent_Score: 6.5 },
    { id: 5, Keyword: 'Email Marketing', Region: 'United States', First_Name: 'Sabir', Last_Name: 'Sayyad', Email_Address: 'sabir@sabzcorp.in', Company_Name: 'Barclays', Action: 'Downloaded White paper', Intent_Score: 9.5 }

  ]);



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Box>
      <Navbar />
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Box sx={{ width: '50%', bgcolor: 'background.paper', mt: 3 }}>

          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Leads Generation" />
            <Tab label="User Management" />
          </Tabs>
        </Box>

      </Box>

      <TabPanel value={value} index={0}>
        <ManageLeads />
      </TabPanel>



      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 3,
        mb:3
      }}>
        <Box sx={{ width: '70%', bgcolor: 'background.paper', borderRadius: "0.75rem", mt: 3 }}>
          <TabPanel value={value} index={1}>
            <UserManagement />
            {/* <AddSalesUser /> */}
          </TabPanel>
        </Box>

      </Box>



      <Footer />
    </Box>
  );
};

export default AdminPage;
