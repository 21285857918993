import {
  EditOutlined,
  DeleteOutlined,
  AttachFileOutlined,
  GifBoxOutlined,
  ImageOutlined,
  MicOutlined,
  MoreHorizOutlined,
} from "@mui/icons-material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import XLSX, { read, utils } from 'xlsx';
import {
  Box,
  Divider,
  Typography,
  InputBase,
  useTheme,
  Button,
  IconButton,
  useMediaQuery,
  TextField,
  Alert,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  MenuItem,
  Grid,
  Stack,
  Paper,
  Backdrop,
  CircularProgress,
  Skeleton,
  Chip,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import Dropzone from "react-dropzone";
import WidgetWrapper from "components/WidgetWrapper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import PieChar from "./PieChar";
import PieCharv1 from "./PieCharv1";
import axios from "axios";
import { API_URL } from "apiconfig";
import Footer from "scenes/footer";
const Dashboard = () => {
  const dispatch = useDispatch();
  const [isImage, setIsImage] = useState(false);
  const [TALFile, setTALFile] = useState(null);
  const [keyword, setkeyword] = useState('');
  const [country, setcountry] = useState('');
  const [account, setaccount] = useState('All');
  const [accountArray, setaccountArray] = useState([]);
  const [TAL, setTAL] = useState(false);
  const { palette } = useTheme();
  const { _id } = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [open, setOpen] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState();
  const [data, setdata] = useState([]);
  const [pieCharv1, setPieCharv1] = useState(true);
  const [pieChar, setPieChar] = useState(false);
  const [pieCharData, setpieCharData] = useState([]);
  const [pieCharv1Data, setpieCharv1Data] = useState([]);
  const [viewability, setviewability] = useState(0);

  const [values, setValues] = useState([]); // Initialize the array state
  const [file, setFile] = useState(null);
  const [displayMapping, setdisplayMapping] = useState(false);
  const role = useSelector((state) => state.role)
  //console.log(role, 'role')
  //console.log(file, 'TALFIle')
  const handleAddValue = () => {
    // console.log(file, 'TALFIle')
    // console.log(TAL, 'TAL')
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);


    if (TALFile && TAL == "Yes") {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: 'binary' });

        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Assuming you want to read data from column A (0-indexed) in this example
        const columnAData = utils.sheet_to_json(worksheet, { header: 0 }) // Change to 0 to skip headers

        if (columnAData.length > 0) {
          //const inputValues = columnAData.map((value) => value.Company_Names);
          const inputValues = columnAData.map((value) => value.Company_Names.trim());
          const nonEmptyValues = inputValues.filter((value) => value !== '');
          setaccountArray(nonEmptyValues);
          //  console.log(inputValues, ';acountArry')
        }
        else {
          setaccount('All')
          setaccountArray([])
        }

      };

      reader.readAsBinaryString(TALFile);
    }
    else {
      // console.log(account, 'account')
      if (account.includes(',')) {

        const inputValues = account.split(',').map((value) => value.trim());
        const nonEmptyValues = inputValues.filter((value) => value !== '');
        setaccountArray(nonEmptyValues);

      }
      else if (account !== 'All' && account !== '' && !account.includes(',')) {
        setaccountArray([account])

      }
      else {
        setaccount('All')
        setaccountArray([])
      }

    }

  };


  const handleClose = () => {
    setOpen(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {role == 'Client' ? null: <GridToolbarExport />  }
        {/* <GridToolbarExport /> */}
      </GridToolbarContainer>
    );
  }

  const handleTalCheck = async (event) => {
    // console.log(event.target.checked)
    setTAL(event.target.value)
    // if (event.target.value == "Yes" ) {
    //   setaccount('All')
    // }

  }


  const searchLeads = async () => {


    setdisplayMapping(true)
    //console.log(accountArray, 'accountArraySearcLeads')
    //api call to get leads
    setTimeout(async () => {
      setdata([
        { id: 1, skeleton: 'yes', Action: 'Downloaded White paper' },
        { id: 2, skeleton: 'yes', Action: 'Downloaded White paper' },
        { id: 3, skeleton: 'yes', Action: 'Downloaded White paper' },
        { id: 4, skeleton: 'yes', Action: 'Downloaded White paper' },
        { id: 5, skeleton: 'yes', Action: 'Downloaded White paper' }

      ])
      setdisplayMapping(false)
      setPieChar(false)
      setPieCharv1(false)
      //console.log(accountArray, 'accountArray')
      const obj = {
        "Keyword": keyword,
        "Region": country === 'All' ? '' : country,
        "Company_Name": accountArray,
      }
      let modifiedData;
      await axios.post(`${API_URL}/leads/identifyLeads`, obj)

        .then((response) => {

          role == 'Client' ?
            modifiedData = response.data.map((item, index) => (
              {
                ...item,
                id: item._id,
                skeleton: index == 0 || index == 1 || index == 2 || index == 3 || index == 4 ? 'no' : 'yes'
              }


            )) :
            modifiedData = response.data.map((item, index) => (
              {
                ...item,
                id: item._id,
              }


            ));
          seterror(false)

          //set data grid
          setdata(modifiedData);

          if (modifiedData.length > 0) {
            const stageArr = []

            const AwarenessCount = modifiedData.filter(item => item.Intent_Score === 6).length;
            const InterestCount = modifiedData.filter(item => item.Intent_Score === 7).length;
            const ConsiderationCount = modifiedData.filter(item => item.Intent_Score === 8).length;
            const DecisionCount = modifiedData.filter(item => item.Intent_Score === 9 || item.Intent_Score === 10).length;
            // const ImpressionsTest = modifiedData.filter(item => item.Intent_Score === 9 || item.Intent_Score === 10).length;

            if (AwarenessCount > 0) {
              let obj = {
                type: 'Awareness',
                value: AwarenessCount,
              }
              stageArr.push(obj)
            }

            if (InterestCount > 0) {
              let obj = {
                type: 'Interest',
                value: InterestCount,
              }
              stageArr.push(obj)
            }

            if (ConsiderationCount > 0) {
              let obj = {
                type: 'Consideration',
                value: ConsiderationCount,
              }
              stageArr.push(obj)
            }

            if (DecisionCount > 0) {
              let obj = {
                type: 'Decision',
                value: DecisionCount,
              }
              stageArr.push(obj)
            }

            setpieCharv1Data(stageArr)
            setPieCharv1(true)

            //prepare view chart data

            const view = []
            let ViewCount = 0;
            let DownloadedCount = 0;
            modifiedData.forEach(item => {
              const actions = item.Action.split(',').map(action => action.trim());
              // console.log(actions, 'actions')
              // Count "Opened" and "Clicked"

              if (actions[0] == 'Viewed') {
                ViewCount++;
              }
              if (actions[0] == 'Downloaded' || actions[0] == 'Enquired') {
                DownloadedCount++;
              }
            });

            if (ViewCount > 0) {
              let obj = {
                type: 'Impressions',
                value: ViewCount,
              }
              view.push(obj)
            }
            if (DownloadedCount > 0) {
              let obj = {
                type: 'Engaged',
                value: DownloadedCount,
              }
              view.push(obj)
            }

            setviewability(modifiedData.length)
            //set pie chart data
            setpieCharData(view)
            setPieChar(true)
          }
        })
        .catch((error) => {

          seterrorMsg(`${error.response.data.message}`)
          seterror(true)
          console.error('Error fetching data:', error);
        });

    }, 2500);






  }

  const handleSearchLeads = async () => {


    if (TAL == "No" || !TAL) {
      if ((TAL == "No" || !TAL) && keyword) {
        //show lock Accounts backdrop and close in 2sec

        //api call handler to get leads
        seterror(false)
        searchLeads();
      }
      else {
        seterrorMsg('Keyword Field is Mandatory')
        seterror(true)
      }
    }
    else {
      if ((TAL == "Yes" || TAL) && TALFile !== null && keyword) {

        //api call handler to get leads
        seterror(false)
        searchLeads();
      }
      else {
        seterrorMsg('Keyword & TAL Upload is Mandatory')
        seterror(true)
      }
    }



  }

  const columns = [
    {
      field: 'Keyword',
      headerName: 'Target Keyword',
      width: 150,
      renderCell: (record) => {
        return (
          <>
            {record.row.skeleton == 'yes' ?
              <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton> :
              <Typography variant="p" width={'100%'} sx={{ overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>{record.value}</Typography>
            }
          </>
        )
      }
    },
    {
      field: 'Region',
      headerName: 'Country',
      width: 150,
      renderCell: (record) => {
        return (
          <>
            {record.row.skeleton == 'yes' ?
              <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton> :
              <Typography variant="p" width={'100%'} sx={{ overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>{record.value}</Typography>
            }
          </>
        )
      }
    },
    {
      field: 'First_Name',
      headerName: 'First Name',
      width: 110,
      renderCell: (record) => {
        return (
          <>
            {record.row.skeleton == 'yes' ?
              <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton> :
              <Typography variant="p" width={'100%'} sx={{ overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>{record.value}</Typography>
            }
          </>
        )
      }
    },
    {
      field: 'Last_Name',
      headerName: 'Last Name',
      width: 160,
      renderCell: (record) => {
        return (
          <>
            {record.row.skeleton == 'yes' ?
              <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton> :
              <Typography variant="p" width={'100%'} sx={{ overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>{record.value}</Typography>
            }
          </>
        )
      }
    },
    {
      field: 'Email_Address',
      headerName: 'Email Address',
      width: 150,
      renderCell: (record) => {
        return (
          <>
            {record.row.skeleton == 'yes' ?
              <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton> :
              <Typography variant="p" width={'100%'} sx={{ overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>{record.value}</Typography>
            }
          </>
        )
      }
    },
    {
      field: 'Company_Name',
      headerName: 'Company Name',
      width: 150,
      renderCell: (record) => {
        return (
          <>
            {record.row.skeleton == 'yes' ?
              <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton> :
              <Typography variant="p" width={'100%'} sx={{ overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>{record.value}</Typography>
            }
          </>
        )
      }
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 150,
      // align: 'center',
      flex: 1,
      // headerAlign: 'center',
      renderCell: (params) => {
        return (
          <>
            {params.row.skeleton == 'yes' ?
              <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton> :
              <div>
                {params.value ? params.value.split(',').map((tag, index) => (
                  <Chip key={index} label={tag} sx={{ margin: '3px', backgroundColor: '#039be5', color: 'white' }} />
                )) : null}
              </div>

            }
          </>
        )
      }
    },
    {
      field: 'Intent_Score',
      headerName: 'Intent Score',
      width: 110,
      align: 'center',
      headerAlign: 'center',

      renderCell: (record) => {
        return (
          <>
            {record.row.skeleton == 'yes' ?
              <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton> :

              //   <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              //   <Button sx={{ fontSize: 25 }} >{ record.value}</Button>
              // </Box>

              <Typography variant="p" textAlign={'center'} fontSize={18} fontWeight={500} color={'#26a69a'} width={'100%'} sx={{ overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>{record.value}</Typography>
            }
          </>
        )
      }
    },
  ];

  // const pieCharData = [
  //   {
  //     type: 'Impressions',
  //     value: 27,
  //   },
  //   {
  //     type: 'Engaged',
  //     value: 25,
  //   },


  // ];
  const countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia", "Austria",
    "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan",
    "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia",
    "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica",
    "Croatia", "Cuba", "Cyprus", "Czech Republic", "Côte d'Ivoire", "Denmark", "Djibouti", "Dominican Republic", "DR Congo", "Ecuador",
    "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France", "Gabon",
    "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti",
    "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan",
    "Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein",
    "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius",
    "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nepal",
    "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", "Oman", "Pakistan", "Panama",
    "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts & Nevis",
    "Saint Lucia", "Samoa", "Sao Tome & Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia",
    "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Korea", "South Sudan", "Spain", "Sri Lanka", "St. Vincent & Grenadines",
    "State of Palestine", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo",
    "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
    "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
  ];

  return (
    <>

      <Box >
        <Grid container spacing={3} mb={1}>
          <Grid item md={3.5} mt={1}>
            {/* <Paper elevation={0} sx={{ borderRadius: "0.75rem" }}> */}
            {pieCharv1 ? <PieCharv1 data={pieCharv1Data} /> : null}

            {/* </Paper> */}
          </Grid>

          <Grid item md={5}>

            <Paper elevation={0} sx={{ borderRadius: "0.75rem", padding: 2, position: "relative" }}>
              <Alert severity="info" sx={{ fontSize: 16, mb: 3, fontWeight: 500 }} >Please Input Details To Get Buyer Insights</Alert>
              {error ? <Alert severity="error" sx={{ fontSize: 16, mt: -2, mb: 2, fontWeight: 500 }} >{errorMsg}</Alert> : null}
              <Box>
                <Grid container spacing={3} mb={4}>

                  <Grid item xs={8} md={8}>
                    <TextField id="standard-basic" label="Search Keyword " fullWidth variant="standard" required
                      onChange={(e) => {
                        e.preventDefault()
                        setkeyword(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <TextField sx={{ width: '100%' }}
                      id="outlined-select-currency"
                      select
                      label="Country"
                      variant="standard"
                      fullWidth
                      defaultValue=''
                      onChange={(e) => {
                        e.preventDefault()
                        setcountry(e.target.value)
                      }}
                    >
                      <MenuItem value='All'>All</MenuItem>
                      {countries.map((country, index) => (
                        <MenuItem key={index} value={country}>
                          {country}
                        </MenuItem>
                      ))}
                      {/* <MenuItem value='All'>All</MenuItem>
                      <MenuItem value='India'> India </MenuItem>
                      <MenuItem value='US'> United States </MenuItem> */}
                    </TextField>
                  </Grid>

                </Grid>

              </Box>

              {/* sx={{ display: 'flex', gap: "1.5rem" }}  */}
              <Box >
                <Grid container spacing={3} >
                  <Grid item md={12}>
                    <Box display={'flex'} >


                      <TextField InputLabelProps={{ shrink: true }} sx={{ mb: 2 }}
                        fullWidth

                        disabled={TAL == "Yes" ? true : false}
                        id="standard-basic" label="Enter Target Account " variant="standard"
                        value={TAL == "Yes" ? 'All' : account}


                        onChange={(e) => {
                          e.preventDefault()
                          setaccount(e.target.value)
                        }}
                      // onBlur={handleSaveEdit} autoFocus

                      />
                      <IconButton disabled={TAL == "Yes" ? true : false}
                        onClick={() => handleAddValue()}
                      // sx={{ p: "0.6rem" }}
                      >
                        <LockOutlinedIcon color={TAL == "Yes" ? 'inherit' : 'success'} />
                      </IconButton>
                    </Box>

                  </Grid>

                </Grid>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button sx={{ fontSize: 20 }} color="success" >OR</Button>
              </Box>

              <Grid container spacing={1}>
                <Grid item md={4}>
                  <FormControl >
                    <FormLabel id="demo-controlled-radio-buttons-group" >Upload TAL</FormLabel>
                    <RadioGroup row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      defaultValue="No"
                      onChange={handleTalCheck}
                    >
                      <FormControlLabel value="Yes" control={<Radio size="medium" />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio size="medium" />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={8}>
                  <Box>
                    {TAL == "Yes" ?
                      <Box flexGrow={1}

                      >
                        <Dropzone
                          acceptedFiles=".xlsx"
                          multiple={false}
                          onDrop={(acceptedFiles) => { setTALFile(acceptedFiles[0]); setFile(acceptedFiles) }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <FlexBetween>
                              <Box
                                {...getRootProps()}
                                border={`2px dashed ${palette.primary.main}`}
                                p="1rem"
                                width="100%"
                                sx={{ "&:hover": { cursor: "pointer" } }}
                              >
                                <input {...getInputProps()} />
                                {!TALFile ? (
                                  <Typography textAlign={'center'}>Drag Drop or Upload TAL Here</Typography>
                                ) : (
                                  <FlexBetween>
                                    <Typography>{TALFile.name}</Typography>
                                    <EditOutlined />
                                  </FlexBetween>
                                )}
                              </Box>
                              {TALFile && (
                                <>

                                  <IconButton
                                    onClick={() => handleAddValue()}
                                    sx={{ width: "8%" }}
                                  >
                                    <LockOutlinedIcon color="success" />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => setTALFile(null)}
                                    sx={{ width: "8%" }}
                                  >
                                    <DeleteOutlined color="error" />
                                  </IconButton>
                                </>
                              )}
                            </FlexBetween>
                          )}
                        </Dropzone>
                      </Box>
                      : null}
                  </Box>
                </Grid>

              </Grid>



              <Divider sx={{ margin: "1.25rem 0" }} />

              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained"

                  onClick={handleSearchLeads}
                >
                  IDENTIFY LEADS
                </Button>
              </Box>
              <Backdrop
                sx={{
                  color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, position: "absolute", borderRadius: "0.75rem",
                  opacity: 0.2
                }}
                open={open}
                onClick={handleClose}
              >
                <Box sx={{ display: 'block', textAlign: 'center', }}>
                  <CircularProgress color="inherit" />
                  <Typography> Locking Target Accounts...</Typography>
                </Box>

              </Backdrop>

            </Paper>


          </Grid>


          <Grid item md={3.5}>
            {/* <Paper elevation={0} sx={{ borderRadius: "0.75rem", padding: 2 }}> */}
            {pieChar ? <PieChar data={pieCharData} viewability={viewability} /> : null}

            {/* </Paper> */}
          </Grid>
        </Grid>

      </Box>


      {/* Data table */}

      <WidgetWrapper m="1rem 0">
        {displayMapping ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2, flexDirection: 'column' }}>
          <CircularProgress />
          <Typography variant="p">Mapping Intent Signals</Typography>
        </Box> : null}

        <Box>
          <DataGrid rows={data}

            getRowHeight={() => 'auto'}
            autoHeight

            columns={columns} initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            slots={{
              toolbar: CustomToolbar,
            }}
            sx={{
              '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
              '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
              '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },

            }} />
        </Box>

      </WidgetWrapper>
      {/* </Box> */}


    </>
  );
};

export default Dashboard;
