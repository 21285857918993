import React, { useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Pie } from '@ant-design/plots';

const PieCharv1 = ({ data }) => {

  const config = {
    autoFit: 'true',
    height: 300,
     data,
    angleField: 'value',
    colorField: 'type',
    color: ({type}) => {
      if (type === 'Decision') {
        return '#4f81bc'
      } else if (type === 'Consideration') {
        return '#e91e63'
      } else if (type === 'Interest') {
        return '#26a69a'
      } else if (type === 'Awareness') {
        return '#2196f3'
      } 
    },
    
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',

      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };

  const memoizedv1Pie = useMemo(() => {
    return(<Pie {...config} />) 
  }, [data]);

  return memoizedv1Pie;
 // return <Pie {...config} />;
};

export default PieCharv1;
