import { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  InputBase,
  Typography,
  Select,
  MenuItem,
  FormControl,
  useTheme,
  useMediaQuery,
  Button,
  Modal,
  Fade,
  Backdrop,
  Divider,
  Grid,
  TextField,
  Alert,
} from "@mui/material";
import {
  Search,
  Message,
  DarkMode,
  LightMode,
  Notifications,
  Help,
  Menu,
  Close,
} from "@mui/icons-material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Formik } from "formik";
import * as yup from "yup";
import SendIcon from '@mui/icons-material/Send';
import HomeIcon from '@mui/icons-material/Home';
import { useDispatch, useSelector } from "react-redux";
import { setMode, setLogout, setModelOpen, setLoginModelOpen, setLogin } from "state";
import { useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import axios from "axios";
import { API_URL } from "apiconfig";



// import {logo} from './logo.jpeg';
const forgetSchema = yup.object().shape({

  email: yup.string().email("Invalid Email Address").required("Email required"),

});

const loginSchema = yup.object().shape({
  email: yup.string().email("Invalid Email Address").required("Email required"),
  password: yup.string().required("Password Required"),
});

const initialValuesForget = {
  email: "",
};

const initialValuesLogin = {
  email: "",
  password: "",
};
const Navbar = ({ hide }) => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const modelOpen = useSelector((state) => state.modelOpen);
  const loginModelOpen = useSelector((state) => state.loginModelOpen);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { palette } = useTheme();
  const [pageType, setPageType] = useState("login");
  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;
  const dark = theme.palette.neutral.dark;
  const background = theme.palette.background.default;
  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;
  const isLogin = pageType === "login";
  const isforget = pageType === "forget";
  const isOTP = pageType === "OTPverify";
  const [error, seterror] = useState(false);
  const [errormsg, seterrormsg] = useState();
  const [isValid, setIsValid] = useState(true);

  const fullName = user ? `${user.firstName} ${user.lastName}` : null;

  const logoutUser = () => {

    dispatch(setLogout())
    navigate('/home')
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    borderRadius: "0.75rem",
    boxShadow: 24,
    pl: 4,
    pr: 4,
    pt: 1
  };
  const Loginstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: "0.75rem",
    boxShadow: 24,
    pl: 6,
    pr: 6,
    pt: 1
  };
  // const modelActionState = useState(modelAction)
  const [open, setOpen] = useState(false);
  const [loginopen, setloginopen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleLoginOpen = () => setloginopen(true);
  const handleClose = () => { dispatch(setModelOpen(false)); setOpen(false) };
  const handleLoginClose = () => { dispatch(setLoginModelOpen(false)); setloginopen(false) };
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [email, setemail] = useState('');
  const [company, setcompany] = useState('');
  const [number, setnumber] = useState();
  const [success, setsuccess] = useState(false);
  const [successPassword, setsuccessPassword] = useState(false);

  // console.log(useSelector((state) => state.modelAction), 'modelAction')

  useEffect(() => {

    if (modelOpen) {
      handleOpen()
    }
  }, [modelOpen])

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setemail(inputEmail);

    // Regular expression to validate an email address
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const isEmailValid = emailRegex.test(inputEmail);

    setIsValid(isEmailValid);
  };

  const header = {
    withCredentials: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },

  }
  const handleSubmit = async () => {
    seterror(false)
    setsuccess(false)
    if (firstname && lastname && email && company && number) {
      const obj = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        company: company,
        number: number
      }

      await axios.post(`${API_URL}/auth/notify`, obj, header)
        .then((response) => {
          if (response.status == 200) {
            seterror(false)
            setsuccess(true)

            setTimeout(() => {
              handleClose()
              setsuccess(false)
            }, 2000);
          }
          else {
            seterror(true)
            seterrormsg(response)
          }

        })
        .catch((err) => {
          console.log(err.response, 'err')
          seterror(true)
          seterrormsg(err.response)

        })
    }
    else {
      setsuccess(false)
      seterrormsg('All Fields is required')
      seterror(true)


    }

  }




  const logoStyle = {
    display: 'block',
    cursor: 'pointer',
    width: '250px', // Adjust the size as needed
  };
  return (
    <>

      <FlexBetween backgroundColor={alt} >
        <FlexBetween gap="1.75rem">
          <img src='/logo.jpeg' alt="Logo" style={logoStyle} onClick={() => navigate("/")} />
          {isNonMobileScreens && (

            <FlexBetween
              backgroundColor={neutralLight}
              borderRadius="9px"
              gap="3rem"
              padding="0.1rem 1.5rem"
            >
              <InputBase placeholder="Search..." />
              <IconButton>
                <Search />
              </IconButton>
            </FlexBetween>
          )}
        </FlexBetween>

        {/* DESKTOP NAV */}
        {isNonMobileScreens ? (
          <FlexBetween gap="2rem">


            <Button onClick={() => navigate("/home")}>Home</Button>

            <Button onClick={() => navigate("/aboutUs")}>About us</Button>
            <Button onClick={handleOpen}>Book A Demo </Button>
            {user ?
              <>
                <Button onClick={() => navigate("/dashboard")}>Dashboard</Button>
                <FormControl variant="standard" value={fullName}>
                  <Select
                    value={fullName}
                    sx={{
                      backgroundColor: neutralLight,
                      width: "150px",
                      borderRadius: "0.25rem",
                      p: "0.25rem 1rem",
                      "& .MuiSvgIcon-root": {
                        pr: "0.25rem",
                        width: "3rem",
                      },
                      "& .MuiSelect-select:focus": {
                        backgroundColor: neutralLight,
                      },
                    }}
                    input={<InputBase />}
                  >
                    <MenuItem value={fullName}>
                      <Typography>{fullName}</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => logoutUser()
                    }>Log Out</MenuItem>
                  </Select>
                </FormControl>

              </>
              :
              <Button variant="contained" startIcon={<SendIcon />}
                // onClick={() => handleLoginOpen()} 
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            }
            <IconButton onClick={() => dispatch(setMode())}>
              {theme.palette.mode === "dark" ? (
                <DarkMode sx={{ fontSize: "25px" }} />
              ) : (
                <LightMode sx={{ color: dark, fontSize: "25px" }} />
              )}
            </IconButton>

          </FlexBetween>

        ) : (
          <IconButton
            onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
          >
            <Menu />
          </IconButton>
        )}

        {/* MOBILE NAV */}
        {!isNonMobileScreens && isMobileMenuToggled && (
          <Box
            position="fixed"
            right="0"
            bottom="0"
            height="100%"
            zIndex="10"
            maxWidth="500px"
            minWidth="300px"
            backgroundColor={background}
          >
            {/* CLOSE ICON */}
            <Box display="flex" justifyContent="flex-end" p="1rem">
              <IconButton
                onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
              >
                <Close />
              </IconButton>
            </Box>

            {/* MENU ITEMS */}
            <FlexBetween
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap="3rem"
            >
              <IconButton
                onClick={() => dispatch(setMode())}
                sx={{ fontSize: "25px" }}
              >
                {theme.palette.mode === "dark" ? (
                  <DarkMode sx={{ fontSize: "25px" }} />
                ) : (
                  <LightMode sx={{ color: dark, fontSize: "25px" }} />
                )}
              </IconButton>
              <Message sx={{ fontSize: "25px" }} />
              <Notifications sx={{ fontSize: "25px" }} />
              <Help sx={{ fontSize: "25px" }} />
              {user ?
                <FormControl variant="standard" value={fullName}>
                  <Select
                    value={fullName}
                    sx={{
                      backgroundColor: neutralLight,
                      width: "150px",
                      borderRadius: "0.25rem",
                      p: "0.25rem 1rem",
                      "& .MuiSvgIcon-root": {
                        pr: "0.25rem",
                        width: "3rem",
                      },
                      "& .MuiSelect-select:focus": {
                        backgroundColor: neutralLight,
                      },
                    }}
                    input={<InputBase />}
                  >
                    <MenuItem value={fullName}>
                      <Typography>{fullName}</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => dispatch(setLogout())}>
                      Log Out
                    </MenuItem>
                  </Select>
                </FormControl> : null
              }

            </FlexBetween>
          </Box>
        )}
      </FlexBetween>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {success ? <Alert severity="success" sx={{ fontSize: 16, fontWeight: 500, mt: 2 }} >Request has been sent...Thank You!!</Alert> : null}
            {error ? <Alert severity="error" sx={{ fontSize: 16, fontWeight: 500, mt: 2 }} >{errormsg}</Alert> : null}

            <Box>
              <Typography sx={{ fontSize: 20, fontWeight: 500, mt: 2 }} color={'primary'} textAlign={'center'}> BOOK A DEMO</Typography>
            </Box>

            {/* <Divider/> */}
            <Typography id="transition-modal-title" variant="h5" component="h2" mt={2}>
              Get introduced to TargetLynk, a Demand Activation Platform by Herr Enterprises.
            </Typography>
            <Box>
              <Grid mb={2} mt={1} container >
                <Grid item md={6} mb={1}>
                  <TextField type="text" label="First Name" size="small" variant="standard" required
                    onChange={(e) => { e.preventDefault(); setfirstname(e.target.value) }} />

                </Grid>
                <Grid item md={6}>
                  <TextField type="text" label="Last Name" size="small" variant="standard" required
                    onChange={(e) => { e.preventDefault(); setlastname(e.target.value) }} />

                </Grid>
              </Grid>
              <Grid mb={2} container >
                <Grid item md={6} mb={1}>
                  <TextField type="email" label="Business Email" size="small" variant="standard" required
                    onChange={handleEmailChange}
                    error={!isValid}
                    helperText={!isValid ? 'Invalid email format' : ''} />

                </Grid>
                <Grid item md={6}>
                  <TextField type="text" label="Company Name" size="small" variant="standard" required
                    onChange={(e) => { e.preventDefault(); setcompany(e.target.value) }} />

                </Grid>
              </Grid>

              <Grid mb={2} container >
                <Grid item md={6} mb={2}>
                  <TextField type="number" label="Phone Number" size="small" variant="standard" required
                    onChange={(e) => { e.preventDefault(); setnumber(e.target.value) }} />

                </Grid>
              </Grid>
              <Divider />
            </Box>

            <Button variant="contained" sx={{ mt: 3, mb: 3, float: 'right' }} onClick={handleSubmit}>Request a demo</Button>
          </Box>
        </Fade>
      </Modal>



    </>
  );
};

export default Navbar;
