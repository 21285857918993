import { Box, Button, Typography } from "@mui/material";
import Navbar from "scenes/navbar";

import { useTypewriter } from "react-simple-typewriter";
import { useEffect, useRef, useState } from "react";
// import video from '../images/video.mp4'
import Footer from "scenes/footer";
const Landing = () => {
    const [isTyping, setIsTyping] = useState(true);

    const stopTyping = () => {
        setIsTyping(false);
    };

    // const [text] = useTypewriter({
    //     words: ['Reveal. Engage. Convert.'],
    //     loop: false,
    // })

    return (
        <>
            <Box>
                <Navbar />

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>



                    < Button sx={{ fontSize: 80, fontWeight: 550, mt: 5, mb: 2, color: '#333333' }} >Reveal. Engage. Convert. </Button>



                </Box>
                {/* < Button sx={{ fontSize: 25, mt: 5 }} className="typewritter" color="warning">Reveal Engage Convert </Button> */}

                <Box
                    width="100%"  // Set the height of the Box to 100%
                    display="flex"
                    alignItems="center"
                    justifyContent="center"

                    mb={5}

                >
                    <video
                        width="80%"   // Set the width of the video to 100%
                        height="20%"  // Set the height of the video to 100%
                        controls
                        autoPlay
                        style={{ marginBottom: 50, borderRadius: "0.75rem" }}  // Add controls to the video element (e.g., play, pause, volume)
                    >
                        <source src="https://res.cloudinary.com/dv9abgwy0/video/upload/v1698184007/jssoo0irwudywakmtbwr.mp4" type="video/mp4" />
                        <Typography variant="p">Your browser does not support the video tag.</Typography>
                    </video>
                </Box>
                <Footer />
            </Box >
        </>
    )

}
export default Landing;