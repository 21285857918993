import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import HomePage from "scenes/homePage";
import LoginPage from "scenes/loginPage";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import AdminPage from "scenes/admin";
import Landing from "scenes/landing";
import About from "scenes/about";
import DataProtection from "scenes/footer/dataprotection";
import Privacy from "scenes/footer/privacy";

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth = Boolean(useSelector((state) => state.token));
  const role = useSelector((state) => state.role)
  // console.log(role, 'role')
  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/home" element={<Landing />} />
            <Route path="/aboutUs" element={<About />} />

            <Route path="/login" element={<LoginPage />} />
            <Route path="/dataProtectionPolicy" element={<DataProtection />} />
            <Route path="/privacyPolicy" element={<Privacy />} />

            <Route
              path="/dashboard"
              element={isAuth ? <HomePage /> : <LoginPage />}
            />
            <Route
              path="/admin"
              element={role && role === 'admin' ? <AdminPage /> : <HomePage />}
            />
            {/* 
            <Route
              path="/landing"
              element={<Landing />}
            /> */}

          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
