import { Box, Grid, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import SideBar from "scenes/widgets/SideBar";
import Dashboard from "scenes/widgets/Dashboard";
import Footer from "scenes/footer";


const HomePage = () => {
  // const isNonMobileScreens = useMediaQuery("(min-width:600px)");
 

  return (
    <Box>
      <Navbar />
      <Box
      width="100%"
      padding="1rem 1%"
      >

        <Grid container spacing={3} mb={3}>
          {/* LEFT PANNEL */}
          <Grid item md={9.5}>

            <Box>
              <Dashboard/>

            </Box>
          </Grid>

          {/* RIGHT PANNEL */}
          <Grid item md={2.5}>
            <Box>
              <SideBar />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer/>
    </Box>
  );
};

export default HomePage;
