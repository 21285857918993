import { useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  Alert,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin } from "state";
import Dropzone from "react-dropzone";
import FlexBetween from "components/FlexBetween";
import axios from "axios";
import { API_URL } from "apiconfig";
const forgetSchema = yup.object().shape({

  email: yup.string().email("Invalid Email Address").required("Email required"),

});

const loginSchema = yup.object().shape({
  email: yup.string().email("Invalid Email Address").required("Email required"),
  password: yup.string().required("Password Required"),
});

const initialValuesForget = {

  email: "",

};

const initialValuesLogin = {
  email: "",
  password: "",
};

const Form = () => {
  const [pageType, setPageType] = useState("login");
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isLogin = pageType === "login";
  const isforget = pageType === "forget";
  const [error, seterror] = useState(false);
  const [errormsg, seterrormsg] = useState();
  const isOTP = pageType === "OTPverify";
  const [success, setsuccess] = useState(false);
  const [successPassword, setsuccessPassword] = useState(false);

  const header = {
    withCredentials: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },

  }
  const forget = async (values, onSubmitProps) => {
    // this allows us to send form info with image
    //console.log(values.email, 'values')
    let obj = {
      "emailID": values.email
    }

    await axios.post(`${API_URL}/auth/OTP`, obj, header)
      .then((response) => {
        if (response.status == 200) {
          seterror(false)
          setPageType("OTPverify")
        }
        else {
          seterror(true)
          seterrormsg(response.data.msg)
        }

      })
      .catch((err) => {
        console.log(err.response.data.msg, 'err')
        seterror(true)
        seterrormsg(err.response.data.msg)

      })
  };

  const verifyOTP = async (values, onSubmitProps) => {
    //// this allows us to send form info with image
    // console.log(values, 'values')

    let obj = {
      "emailID": values.email,
      "OTP": values.otp,
      "password": values.newPassword
    }

    await axios.post(`${API_URL}/auth/OTPVerify`, obj)
      .then((response) => {
        // console.log(response.data, 'response')
        if (response.status == 200) {

          seterror(false)
          setsuccessPassword(true)
          setTimeout(() => {
            setPageType('login')
            setsuccessPassword(false)
          }, 2500);

        }
        else {
          seterror(true)
          seterrormsg(response.data.msg)
        }

      })
      .catch((err) => {
        // console.log(err, 'err')
        seterror(true)
        seterrormsg(err.response.data.msg)

      })

  };

  const login = async (values, onSubmitProps) => {
    const loggedInResponse = await fetch(`${API_URL}/auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    });


    if (loggedInResponse.status !== 200) {
      let msg = await loggedInResponse.json()
      seterrormsg(msg.msg)
      seterror(true)
    }
    else {
      seterror(false)
    }
    const loggedIn = await loggedInResponse.json();
    // console.log(loggedIn, 'loggedIn')
    onSubmitProps.resetForm();
    if (loggedIn) {

      dispatch(
        setLogin({
          user: loggedIn.user,
          token: loggedIn.token,
        })
      );
      navigate("/dashboard");
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    if (isLogin) await login(values, onSubmitProps);
    if (isforget) await forget(values, onSubmitProps);
    if (isOTP) await verifyOTP(values, onSubmitProps);
  };
  return (


    <Formik
      onSubmit={handleFormSubmit}
      initialValues={isLogin ? initialValuesLogin : initialValuesForget}
      validationSchema={isLogin ? loginSchema : forgetSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          {error ? <Alert severity="error" sx={{ fontSize: 16, mt: 1, mb: 3, fontWeight: 500 }}  >{errormsg}</Alert> : null}
          {successPassword ? <Alert severity="success" sx={{ fontSize: 16, mt: 2, mb: 2, fontWeight: 500 }}  >
            Password has been reset, Please login with new password !!</Alert> : null}
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >

            {isLogin && (
              <>
                <TextField
                  size="small"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={Boolean(touched.email) && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  size="small"
                  label="Password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={Boolean(touched.password) && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: "span 4" }}
                />
              </>
            )}


            {isforget && (
              <>
                <TextField
                  size="small"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={Boolean(touched.email) && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 4" }}
                />
              </>
            )}

            {isOTP && (
              <>
                <TextField
                  size="small"
                  label="Enter OTP"
                  type="number"
                  inputProps={{
                    maxLength: 5,
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  // value={values.email}
                  name="otp"
                  error={Boolean(touched.email) && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 4" }}
                />

                <TextField
                  size="small"
                  label="Enter New Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  // value={values.email}
                  name="newPassword"
                  error={Boolean(touched.email) && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 4" }}
                />
              </>
            )}





          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mt: 4, mb: 3 }}
            >
              {isLogin ? "LOGIN" : isforget ? "SEND OTP" : "VERIFY OTP & RESET"}
            </Button>

            <Typography
              onClick={() => {
                seterror(false)
                setPageType(isLogin ? "forget" : isforget ? "login" : isOTP ? "forget" : "login");
                resetForm();
              }}
              sx={{
                // textDecoration: "underline",
                color: palette.primary.main,
                "&:hover": {
                  cursor: "pointer",
                  color: palette.primary.light,

                },
                mb: 2
              }}
            >
              {isLogin
                ? "Don't remember your password?  Reset here." : isforget ? "Back to login" : isOTP ? "Back to Forget password"
                  : "Back to login"}
            </Typography>

          </Box>



        </form>
      )}
    </Formik>
  );
};

export default Form;
