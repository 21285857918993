import { Alert, BottomNavigation, BottomNavigationAction, Box, Chip, CircularProgress, Grid, IconButton, LinearProgress, Skeleton, Tab, Tabs, TextareaAutosize, Typography, gridClasses, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useCallback, useEffect, useRef, useState } from "react";
// import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridToolbar,
    useGridApiContext,
} from '@mui/x-data-grid';
import axios from "axios";
import { useTheme } from "@emotion/react";
import { grey } from "@mui/material/colors";
import { DeleteOutlined, EditOutlined, Padding, SipOutlined } from "@mui/icons-material";

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dropzone from "react-dropzone";
import FlexBetween from "components/FlexBetween";
import { read, utils } from "xlsx";
import { API_URL } from "apiconfig";



const initialRows = [
    { id: 1, Keyword: 'Email Marketing', Region: 'United States', First_Name: 'Sabir', Last_Name: 'Sayyad', Email_Address: 'sabir@sabzcorp.in', Company_Name: 'Barclays', Action: 'Downloaded White paper', Intent_Score: 9.5 },
    { id: 2, Keyword: 'Email Marketing', Region: 'United States', First_Name: 'Adnan', Last_Name: 'Attar', Email_Address: 'adnan@capgemini.com', Company_Name: 'Capgemini', Action: 'Downloaded White paper', Intent_Score: 2.5 },
    { id: 3, Keyword: 'Email Marketing', Region: 'United States', First_Name: 'Sohel', Last_Name: 'Shaikh', Email_Address: 'sohel@atos.com', Company_Name: 'Atos', Action: 'Downloaded White paper', Intent_Score: 4.5 },
    { id: 4, Keyword: 'Email Marketing', Region: 'United States', First_Name: 'Rameez', Last_Name: 'Tamboli', Email_Address: 'rameez@syntel.com', Company_Name: 'Syntel', Action: 'Downloaded White paper', Intent_Score: 6.5 },
    { id: 5, Keyword: 'Email Marketing', Region: 'United States', First_Name: 'Sabir', Last_Name: 'Sayyad', Email_Address: 'sabir@sabzcorp.in', Company_Name: 'Barclays', Action: 'Downloaded White paper', Intent_Score: 9.5 }

]
const ManageLeads = () => {
    const { palette } = useTheme();
    const [rows, setRows] = useState([{ id: 1, skeleton: 'yes', Keyword: 'Test,test', Action: 'Test,test' }, { id: 2, skeleton: 'yes', Keyword: 'Test,test', Action: 'Test,test' }, { id: 3, skeleton: 'yes', Keyword: 'Test,test', Action: 'Test,test' }, { id: 4, skeleton: 'yes', Keyword: 'Test,test', Action: 'Test,test' }, { id: 5, skeleton: 'yes', Keyword: 'Test,test', Action: 'Test,test' }]);

    const [rowModesModel, setRowModesModel] = useState({});
    const [showLinear, setshowLinear] = useState(false);
    const [progress, setprogress] = useState(0);
    const [error, seterror] = useState(false);
    const [errorMsg, seterrorMsg] = useState();
    const [showDropZone, setshowDropZone] = useState(false);
    const [LeadsFile, setLeadsFile] = useState(null);
    useEffect(() => {
        getLeads()
    }, []);

    useEffect(() => {
        if (showLinear) {
            const timer = setInterval(() => {
                setprogress((oldProgress) => {
                    if (oldProgress === 100) {
                        return 0;
                    }
                    const diff = Math.random() * 10;
                    return Math.min(oldProgress + diff, 100);
                });
            }, 500);

            return () => {
                clearInterval(timer);
            };
        }

    }, [showLinear]);


    const getLeads = async () => {

        await axios.get(`${API_URL}/leads`)

            .then((response) => {

                const modifiedData = response.data.map((item) => ({
                    ...item,
                    id: item._id,
                }));
                setRows(modifiedData); // Set the fetched data in state
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }

    const columns = [
        {
            field: 'Keyword',
            headerName: 'Target Keyword',
            width: 150,
            flex: 1,
            editable: true,
            renderCell: (params) => {
                // let tagsArray
                // {params.value ?  tagsArray = params.value.split(','): null}
                return (
                    <>



                        {params.row.skeleton ?
                            <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton> :

                            <div>
                                {params.value ? params.value.split(',').map((tag, index) => (
                                    <Chip key={index} label={tag} sx={{ margin: '3px', backgroundColor: '#26a69a', color: 'white' }} />
                                )) : null
                                }
                            </div>
                            // <Typography variant="p" width={'100%'} sx={{ overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>{record.value}</Typography>
                        }
                    </>
                )

            },
        },
        {
            field: 'Region',
            headerName: 'Country',
            width: 150,
            editable: true,
            renderCell: (record) => {
                return (
                    <>
                        {record.row.skeleton ?
                            <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton> :
                            <Typography variant="p" width={'100%'} sx={{ overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>{record.value}</Typography>
                        }
                    </>
                )
            }
        },
        {
            field: 'First_Name',
            headerName: 'First Name',
            width: 110,
            editable: true,
            renderCell: (record) => {
                return (
                    <>
                        {record.row.skeleton ?
                            <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton> :
                            <Typography variant="p" width={'100%'} sx={{ overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>{record.value}</Typography>
                        }
                    </>
                )
            }
        },
        {
            field: 'Last_Name',
            headerName: 'Last Name',
            width: 160,
            editable: true,
            renderCell: (record) => {
                return (
                    <>
                        {record.row.skeleton ?
                            <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton> :
                            <Typography variant="p" width={'100%'} sx={{ overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>{record.value}</Typography>
                        }
                    </>
                )
            }
        },
        {
            field: 'Email_Address',
            headerName: 'Email Address',
            width: 150,
            editable: true,
            renderCell: (record) => {
                return (
                    <>
                        {record.row.skeleton ?
                            <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton> :
                            <Typography variant="p" width={'100%'} sx={{ overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>{record.value}</Typography>
                        }
                    </>
                )
            }
        },
        {
            field: 'Company_Name',
            headerName: 'Company Name',
            width: 150,
            editable: true,
            renderCell: (record) => {
                return (
                    <>
                        {record.row.skeleton ?
                            <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton> :
                            <Typography variant="p" width={'100%'} sx={{ overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>{record.value}</Typography>
                        }
                    </>
                )
            }
        },
        {
            field: 'Action',
            headerName: 'Action',
            width: 150,
            // align: 'center',
            // headerAlign: 'center',
            flex: 1,
            editable: true,
            renderCell: (params) => {
                return (
                    <>
                        {params.row.skeleton ?
                            <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton> :
                            <div>
                                {params.value ? params.value.split(',').map((tag, index) => (
                                    <Chip key={index} label={tag} sx={{ margin: '3px', backgroundColor: '#039be5', color: 'white' }} />
                                )) : null}
                            </div>

                        }
                    </>
                )
            }
        },
        {
            field: 'Intent_Score',
            headerName: 'Intent Score',
            width: 110,
            align: 'center',
            headerAlign: 'center',

            editable: true,
            renderCell: (record) => {
                return (
                    <>
                        {record.row.skeleton ?
                            <Skeleton sx={{ height: 50, width: '100%' }} variant="text"></Skeleton> :
                            <Typography variant="p" textAlign={'center'} fontSize={18} fontWeight={500} color={'#26a69a'} width={'100%'} sx={{ overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>{record.value}</Typography>
                        }
                    </>
                )
            }
        },

        {
            field: 'actions',
            type: 'actions',
            headerName: 'Operations',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {

                    return (
                        [
                            <GridActionsCellItem
                                icon={<SaveIcon />}
                                label="Save"
                                sx={{
                                    color: 'primary.main',
                                }}
                                onClick={handleSaveClick(id)}
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={handleCancelClick(id)}
                                color="inherit"
                            />,
                        ])
                }

                return (

                    [
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit"
                            className="textPrimary"
                            onClick={handleEditClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={handleDeleteClick(id)}
                            color="error"
                        />,
                    ])



            },
        },
    ];

    const uploadLeads = async () => {
        // console.log(LeadsFile, 'LeadsFile')
        setshowLinear(true)
        if (LeadsFile) {
            const reader = new FileReader();

            reader.onload = async (e) => {
                const data = e.target.result;
                const workbook = read(data, { type: 'binary' });

                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                // Assuming you want to read data from column A (0-indexed) in this example
                const columnAData = utils.sheet_to_json(worksheet, { header: 0 }) // Change to 0 to skip headers

                // console.log(columnAData, 'columnAData')
                await axios.post(`${API_URL}/leads/massUploadLeads`, columnAData)
                    .then((response) => {
                        setshowLinear(false)
                        seterror(false)

                        const modifiedData = response.data.map((item) => ({
                            ...item,
                            id: item._id,
                        }));
                        setRows(modifiedData);


                    })
                    .catch((error) => {
                        setshowLinear(false)
                        seterrorMsg(`${error.response.data.message}`)
                        seterror(true)
                        // console.error('Error fetching data:', error);
                    });


            };

            reader.readAsBinaryString(LeadsFile);
        }
    }

    function EditToolbar(props) {
        const { setRows, setRowModesModel } = props;
        const apiRef = useGridApiContext();

        const handleClick = () => {
            const id = uuidv4() + 'TEST'
            setRows((oldRows) => [{ id, isNew: true }, ...oldRows]);

            setRowModesModel((oldModel) => ({
                ...oldModel,
                [id]: { mode: GridRowModes.Edit, fieldToFocus: 'Keyword' },
            }));
        };

        const handleGetSelectedRows = () => {

            const rowData = apiRef.current.getSelectedRows();

            if (rowData.size !== 0) {
                setshowLinear(true)
                let selectedRows = []
                for (const [key, value] of rowData) {
                    // console.log(value.id, 'rowid')
                    selectedRows.push(value.id)
                }
                handleMassDelete(selectedRows)
            }
            else {
                seterror(true)
                seterrorMsg('Select Leads Checkbox To Perform Mass Delete !! ')
            }


        };

        const handleMassDelete = async (selectedRows) => {

            // console.log(selectedRows, 'selectedRows')

            seterror(false)
            const obj = { idsToDelete: selectedRows }
            await axios.post(`${API_URL}/leads/massDeleteLeads`, obj)
                .then((response) => {

                    setshowLinear(false)
                    setprogress(100)
                    const modifiedData = response.data.map((item) => ({
                        ...item,
                        id: item._id,
                    }));
                    setRows(modifiedData);


                })
                .catch((error) => {

                    setshowLinear(false)
                    setprogress(100)
                    seterrorMsg(`${error.response.data.message}`)
                    seterror(true)
                    // console.error('Error deleting data:', error);
                });
            //perform api call to delete rows

        }

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    Add leads
                </Button>
                {/* <Button color="primary" startIcon={<CloudUploadIcon />} onClick={setshowDropZone(true)}>
                    Mass upload
                </Button> */}
                <Button color="primary" startIcon={<DeleteIcon />} onClick={handleGetSelectedRows}>
                    Mass Delete
                </Button>
                <GridToolbar />

            </GridToolbarContainer>
        );
    }

    const customEditRowModelProps = {
        _editing: true, // Set this to true to apply custom styles when in edit mode
    };

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });

    };

    const handleSaveClick = (id) => () => {
        setshowLinear(true)
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => async () => {
        setshowLinear(true)

        await axios.delete(`${API_URL}/leads/deleteLeads/${id}`)
            .then((response) => {
                setshowLinear(false)
                setprogress(100)
                setRows(rows.filter((row) => row.id !== id));
            })
            .catch((error) => {
                //console.error('Error deleting data:', error);
            });
        //peform api call to delete this id
    };

    const handleCancelClick = (id) => () => {
        seterror(false)
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = async (newRow) => {
        const updatedRow = { ...newRow, isNew: false };

        let obj
        let opsName
        if (updatedRow._id) {
            // console.log(updatedRow, 'update data')
            obj = {
                "Keyword": updatedRow.Keyword,
                "Region": updatedRow.Region,
                "First_Name": updatedRow.First_Name,
                "Last_Name": updatedRow.Last_Name,
                "Email_Address": updatedRow.Email_Address,
                "Company_Name": updatedRow.Company_Name,
                "Action": updatedRow.Action,
                "Intent_Score": updatedRow.Intent_Score,
            }
            opsName = `updateLeads/${updatedRow._id}`
        }
        else {
            obj = {
                "Keyword": updatedRow.Keyword,
                "Region": updatedRow.Region,
                "First_Name": updatedRow.First_Name,
                "Last_Name": updatedRow.Last_Name,
                "Email_Address": updatedRow.Email_Address,
                "Company_Name": updatedRow.Company_Name,
                "Action": updatedRow.Action,
                "Intent_Score": updatedRow.Intent_Score,
            }
            opsName = `addLeads`

        }

        await axios.post(`${API_URL}/leads/${opsName}`, obj)
            .then((response) => {
                seterror(false)
                setshowLinear(false)
                setprogress(100)
                setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
            })
            .catch((error) => {

                setshowLinear(false)
                setprogress(100)
                seterrorMsg(`${error.response.data.message}`)
                seterror(true)
                console.error('Error add/updating data:', error);
            });
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };



    return (
        <>

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>

                <Box sx={{ width: '95%', bgcolor: 'background.paper', mt: 3, borderRadius: "0.75rem" }}>
                    {showLinear ?
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress variant="determinate" value={progress} />
                        </Box> : null
                    }


                    {error ? <Alert severity="error" sx={{ fontSize: 16, fontWeight: 500 }} >{errorMsg}</Alert> : null}

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 2
                    }}>
                        <Box sx={{ width: '50%', bgcolor: 'background.paper', mt: 3 }}>

                            <Dropzone
                                acceptedFiles=".xlsx"
                                multiple={false}
                                onDrop={(acceptedFiles) => { setLeadsFile(acceptedFiles[0]); }}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <FlexBetween>
                                        <Box
                                            {...getRootProps()}
                                            border={`2px dashed ${palette.primary.main}`}
                                            p="1rem"
                                            width="100%"
                                            sx={{ "&:hover": { cursor: "pointer" } }}
                                        >
                                            <input {...getInputProps()} />
                                            {!LeadsFile ? (
                                                <Typography textAlign={'center'}>Drag Drop/Upload Leads For MASS UPLOAD...</Typography>
                                            ) : (
                                                <FlexBetween>
                                                    <Typography>{LeadsFile.name}</Typography>
                                                    <EditOutlined />
                                                </FlexBetween>
                                            )}
                                        </Box>
                                        {LeadsFile && (
                                            <>
                                                <IconButton
                                                    onClick={() => uploadLeads()}
                                                    sx={{ width: "7%" }}
                                                >
                                                    <CloudUploadIcon color="primary" />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => setLeadsFile(null)}
                                                    sx={{ width: "7%" }}
                                                >
                                                    <DeleteOutlined color="error" />
                                                </IconButton>
                                            </>
                                        )}
                                    </FlexBetween>
                                )}
                            </Dropzone>
                        </Box>

                    </Box>
                    <DataGrid

                        rows={rows}
                        columns={columns} initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 25,
                                },
                            },
                        }}
                        pageSizeOptions={[25, 50, 100]}
                        checkboxSelection
                        // getRowSpacing={getRowSpacing}

                        getRowHeight={() => 'auto'}
                        autoHeight
                        editMode="row"
                        editRowModelProps={customEditRowModelProps}
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        slots={{
                            toolbar: EditToolbar,
                        }}
                        slotProps={{
                            toolbar: { setRows, setRowModesModel },
                        }}


                        sx={{
                            m: 3,
                            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },

                        }}
                    />
                </Box>

            </Box>

        </>
    )
}
export default ManageLeads;